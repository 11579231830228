import { graphql } from "gatsby"
import Img from "gatsby-image"
import get from "lodash.get"
import React from "react"
import styled from "styled-components"

import CTA from "../components/CTA"
import Container from "../components/Container"
import SEO from "../components/SEO"
import style from "../styles/index.module.scss"

const Image = styled(Img)`
  align-self: center;
`

const SubTitle = styled.h2`
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 24px;
`

export default function Home({ data }) {
  const me = get(data, "file.childImageSharp.fixed", null)

  return (
    <React.Fragment>
      <SEO title="Home" />
      <section className={style.mainhero}>
        <div className="hero-body level mb-0 is-flex px-0">
          <Container>
            <h1
              className={`title is-spaced is-size-3-mobile is-size-2-tablet ${style.title}`}
            >
              I build Mobile & Web applications
            </h1>
            <SubTitle className="subtitle is-size-4-desktop is-size-5-touch">
              I code beautifully simple things, and I love what I do.
            </SubTitle>
          </Container>
        </div>
        {me ? <Image fixed={me} /> : null}
      </section>
      <section
        style={{ maxWidth: 700, margin: "0 auto", backgroundColor: "black" }}
        className="mb-6 px-5 py-5"
      >
        <div className="container">
          <p className="title is-spaced has-text-white is-size-5-mobile is-size-3-tablet">
            Hi, I’m David. I’m a full-stack developer with over 6+ years of
            experiences in wide range of coding disciplines.
          </p>
        </div>
      </section>
      <section className="mb-6 content">
        <Container>
          <p className="is-size-6-mobile is-size-5 mb-5">
            You can also call me a React developer, a freelancer, a backend
            architect, a team leader or by any other market defined
            function-title. I prefer to keep learning, continue challenging
            myself, and do interesting things that matter. My abundant energy
            fuels me in the pursuit of many interests, hobbies and areas of
            study. I’m a fast learner, able to pick up new skills and juggle
            different projects and roles with relative ease. My personality type
            is{" "}
            <a
              href="https://www.16personalities.com/entp-personality"
              target="_blank"
              rel="noreferrer"
              title="ENTP Personality"
            >
              ENTP
            </a>
            .
          </p>
          <p className="is-size-6-mobile is-size-5 mb-5">
            I've done remote work for agencies, consulted for startups, and
            collaborated with talented people to create digital products for
            both business and consumer use. Sometimes I can’t fulfill your
            wishes alone. I have an extensive network of other freelance
            developers who can help with more demanding tasks.
          </p>
        </Container>
      </section>
      <section className="py-6 has-background-white-ter">
        <Container>
          <h1 className="title">How can i help you?</h1>
          <div className="columns">
            <div className="column">
              <h3
                className={`title is-spaced has-text-white is-size-5-mobile is-size-3-tablet ${style.sectionTitle}`}
              >
                Web applications
              </h3>
              <ul className="is-size-6-mobile is-size-5 mb-5">
                <li>
                  I develop online portals, applications and web apps with the
                  latest technologies.
                </li>
                <li>Pixel perfect design implementation</li>
                <li>
                  Portals with user login, groups, ownership levels, permissions
                  and entitlements.
                </li>
                <li>
                  Automate manually processes and enable your various systems to
                  talk to each other. Integrate with third party API's to
                  innovate and deliver results.
                </li>
              </ul>
            </div>
            <div className="column">
              <h3
                className={`title is-spaced has-text-white is-size-5-mobile is-size-3-tablet ${style.sectionTitle}`}
              >
                Mobile applications
              </h3>
              <ul className="is-size-6-mobile is-size-5 mb-5">
                <li>
                  Developing cross-platform - iOS & Android - application with
                  the latest technologies
                </li>
                <li>Location & Map services</li>
                <li>Payment and billing integration</li>
                <li>Push notifications</li>
                <li>Offline-first approach</li>
                <li>Continuous Integration & Continuous Delivery</li>
              </ul>
            </div>
          </div>
        </Container>
      </section>
      <CTA />
    </React.Fragment>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "sml.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 500) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
